.blog-tabs {
  .MuiTabs-scroller .MuiTabs-flexContainer {
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }
  }
}

.blog-content {
  width: 80%;
  margin: 50px auto;

  p {
    margin: 20px 0;
  }
  h1,
  h2,
  h3 {
    font-weight: 600;
  }
  h1 {
    font-size: 40px;
    @media screen and (max-width: 600px) {
      font-size: 32px;
    }
  }
  h2 {
    font-size: 32px;
    @media screen and (max-width: 600px) {
      font-size: 26px;
    }
  }
  h3 {
    font-size: 26px;
    @media screen and (max-width: 600px) {
      font-size: 20px;
    }
  }
  a {
    color: #196e82;
  }
  a:hover {
    text-decoration: underline;
  }
  figure {
    margin: 20px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      display: block;
      margin-top: 10px;
      font-size: 14px;
    }
  }
  code {
    padding: 0 5px;
    background-color: #e2e7e9;
  }
  .kg-btn {
    background-color: #196e82;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
  }
  .kg-btn:hover {
    text-decoration: none;
  }
  .kg-align-center {
    display: flex;
    .kg-btn {
      margin: 0 auto;
    }
  }
  iframe {
    width: 100%;
    min-height: 500px;
    @media screen and (max-width: 600px) {
      min-height: 350px;
    }
  }
}
