/* @import url('https://fonts.googleapis.com/css2?family=Sora:wght@400;600;700&display=swap'); */

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --font-family: 'Sora', sans-serif;
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
} */
@font-face {
  font-family: 'Sora';
  src: url('../fonts/sora.ttf') format('truetype');
}

body {
  color: rgb(var(--foreground-rgb));
  scroll-behavior: smooth;
  overflow-x: hidden;
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
  font-family: 'Sora', sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
